.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-sub-container {
  flex: 1;
}

.MuiAlert-message {
  display: flex;
  align-items: center;
}
